.App {
  text-align: center;
}

.App-header {
  background-color: #191414;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.headerText {
  color: #1ed760;
}

.login {
  color: white;
  font-size: 39px;
  font-weight: 800;
  text-decoration: none;
}

.login:hover {
  color: #1DB954;
  cursor: pointer;
}

.logout {
  margin-top: 50px;
  background-color: #191414;
  border: none;
  color: white;
  font-size: 19px;
  font-weight: 800;
}

.logout:hover {
  color: #1DB954;
  cursor: pointer;
}

.statCont {
  background-color: black;
  padding: 10px;
  width: 50vw;
  border-radius: 13px;
}

.statCont:hover {
  background-color: #1DB954;
  cursor: pointer;
}

.artistImg {
  /* height: 400px;
  width: 400px; */
  height: 25rem;
  width: 25rem;
  border-radius: 13px;
  margin-left: 20px;
  margin-right: 20px
}

.artistImg:hover {
  opacity: 40%;
  cursor: pointer;
}

.artistImgCont {
  /* width: 55vw; */
  height: 400px;
}

.artistInfoCont {
  display:inline-block;
  flex-direction: row;
  margin-top: -100px
}

.artistName {
  display:inline-block;
  margin-right: 10px;
  margin-left: 10px;
  color: #1ed760;
  font-weight: 700;
  text-decoration: none;
}

.artistName:hover {
  text-decoration: underline;
}